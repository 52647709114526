.console-bgnd {
    position: fixed; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: 100%; 
    background: black;
    z-index: 1;
    font-family: 'Press Start 2P', cursive;
    text-align: left;
    color: rgb(89, 255, 0);
}

.appearing-text {
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    font-size: calc(1vw + 1rem);
    color: red;
}

.typewriter {
    overflow: hidden;
    border-right: .15em solid rgb(89, 255, 0); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    -webkit-animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
            animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @-webkit-keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @-webkit-keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(89, 255, 0); }
  }
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(89, 255, 0); }
  }

  .nocursor {
    /*overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid black; /* The typwriter cursor */
    -webkit-animation: none;
            animation: none;
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
  }

  #command {
    caret-color: red;
    color: red;
    font-family: 'Press Start 2P', cursive;
    text-align: left;
    background-color: black;
    border: none;
  }

  #command:focus {
    outline: none;
  }

  #cube {
    margin: 50vh auto 0;
    font-size: 2rem;
    width: 4em; 
    height: 4em;
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
     -webkit-animation: spin 10s linear 0.5;
             animation: spin 10s linear 0.5;
     -webkit-animation-fill-mode: forwards;
             animation-fill-mode: forwards;
  }

  #cube > div {
    position: absolute;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-animation: metal 2s linear 1;
            animation: metal 2s linear 1;
    -webkit-animation-delay: 6s;
            animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes spin {
    from { -webkit-transform: rotateX(-0.1turn) rotateY(0turn); transform: rotateX(-0.1turn) rotateY(0turn); }
    to { -webkit-transform: rotateX(-0.1turn) rotateY(1turn); transform: rotateX(-0.1turn) rotateY(1turn); }
  }
  
  @keyframes spin {
    from { -webkit-transform: rotateX(-0.1turn) rotateY(0turn); transform: rotateX(-0.1turn) rotateY(0turn); }
    to { -webkit-transform: rotateX(-0.1turn) rotateY(1turn); transform: rotateX(-0.1turn) rotateY(1turn); }
  }

  @-webkit-keyframes metal {
    from {color: rgb(89, 255, 0);}
    to {color: red;}
  }

  @keyframes metal {
    from {color: rgb(89, 255, 0);}
    to {color: red;}
  }

  .pos1 {
    -webkit-transform: translate3d(2em, 0em, 3em);
            transform: translate3d(2em, 0em, 3em)
  }
  .pos2 {
    -webkit-transform: translate3d(0em, 0em, 3em);
            transform: translate3d(0em, 0em, 3em)
  }
  .pos3 {
    -webkit-transform: translate3d(2em, 0em, 1em);
            transform: translate3d(2em, 0em, 1em)
  }
  .pos4 {
    -webkit-transform: translate3d(0em, 0em, 1em);
            transform: translate3d(0em, 0em, 1em)
  }
  .pos5 {
    -webkit-transform: translate3d(2em, 0em, -1em);
            transform: translate3d(2em, 0em, -1em)
  }
  .pos6 {
    -webkit-transform: translate3d(0em, 0em, -1em);
            transform: translate3d(0em, 0em, -1em)
  }
  .pos7 {
    -webkit-transform: translate3d(2em, 0em, -3em);
            transform: translate3d(2em, 0em, -3em)
  }
  .pos8 {
    -webkit-transform: translate3d(0em, 0em, -3em);
            transform: translate3d(0em, 0em, -3em)
  }
body {
    text-align: center ;
}

.contactbttn {
    font-family: 'Barlow', sans-serif; 
    font-size: 1.3rem;
    margin-left: 10px;
}

.toppbar {
    background-image: linear-gradient(to right, #222831, #222831);
}

.titles {
    color: #EEEEEE; 
    font-family: 'Playfair Display', serif;
    font-size: calc(1.3rem + 0.5vw);
}

.selectable-color {
    transition: color .2s ease-in;
}


.selectable-color:hover { 
    color: #01aeb8;
}


.navbar-toggler:focus {
    box-shadow: 3px 3px #01aeb8;
}

.navbar-toggler:not(:hover) {
    box-shadow: 0px 0px #01aeb8;
}

.fixed-height {
    min-height: 3.8rem;
}

.body-titles {
    color: #EEEEEE; 
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

.subbody-titles {
    color: #EEEEEE; 
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
}

.subtitles {
    font-family: 'Barlow', sans-serif;
    color: #EEEEEE;
    font-size: 1.5rem;
}

.sanstitles {
    font-family: 'Barlow', sans-serif;
    color: #EEEEEE;
    font-size: 1.3rem;
}

.background {
    background-color:hsla(216,18%,16%,1);
background-image:
radial-gradient(at 97% 94%, hsla(183,95%,63%,0.27) 0px, transparent 50%),
radial-gradient(at 69% 25%, hsla(0,0%,36%,1) 0px, transparent 50%),
radial-gradient(at 75% 61%, hsla(176,25%,69%,1) 0px, transparent 50%),
radial-gradient(at 0% 98%, hsla(161,100%,50%,1) 0px, transparent 50%),
radial-gradient(at 32% 64%, hsla(216,18%,16%,1) 0px, transparent 50%),
radial-gradient(at 13% 84%, hsla(306,0%,36%,1) 0px, transparent 50%),
radial-gradient(at 24% 28%, hsla(183,98%,36%,1) 0px, transparent 50%);
}

.parallax-dude {
    background-image: url(/static/media/computer.6494b913.jpg);
    background-repeat: no-repeat;
}

.parallax-music {
    background-image: url(/static/media/music.d820c6ba.jpg);
    background-repeat: no-repeat;
}

.parallax-squares {
    background-image: url(/static/media/beagleboard.a5fc8ca3.jpg);
    background-repeat: no-repeat;
    opacity: 0.3;
}

.emptyspace {
    height: 100px;
}

.foreground {
    z-index: 5;
}

.parallax {
  
    /* Set a specific height */
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center; 
    background-size: 100%; 
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .brick {
    min-height: 440px;
  }

  .mask-a {
    -webkit-mask-image: url(/static/media/plx.21879497.png);
  mask-image: url(/static/media/plx.21879497.png);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat; 
  }

.fixed-image {
    position: relative;
    top: 0;
    width: 100%;
    z-index: -1;
}

.overlay {
    background: inherit;
    position: absolute;
    top: 0rem;
}

.underlay {
    background: inherit;
    position: absolute;
    bottom: 0rem;
}

.off-center {
    padding:  3% 5% 8% 5%;
    text-align: left;
}

.shadoww {
    box-shadow:  5px 3px 10px 4px #222831;
}

.dots {
    border-style: dotted;
    border-color: #EEEEEE;
    border-width: 20px 0 0 0;
    width: 180px;
}

.custom-shape-divider {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.custom-shape-divider svg {
    width: calc(121% + 1.3px);
    height: 114px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.custom-shape-divider .shape-fill {
    fill: #222831;
} 

.custom-shape-divider-top-1693047837 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1693047837 svg {
    position: relative;
    display: block;
    width: calc(175% + 1.3px);
    height: 105px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.custom-shape-divider-top-1693047837 .shape-fill {
    fill: #EEEEEE;
}

.bgndbttncolour {
    background-color: #EEEEEE;
}

.wavedivformat {
    position: relative; 
    padding-top: 50px;
}

.highlight-isaac {
    color: #01aeb8;
}

.contacttitle {
    font-family: 'Playfair Display', serif;
}

.offcanvas-body {
    font-family: 'Barlow', sans-serif;
}

.contactlinktext {
    color: #555d69; 
    text-decoration: none;
}

div.relativeclassgeneral {
    position: relative;
}

.sdt {
    top: 25px;
}

.sdb {
    bottom: 25px;
}

.institutiontitle {
    font-size: 1.1rem; 
    color: #555d69;
}

.formathr {
    border-style: none; margin-top: 0px;
}

a.leftaligned {
    margin-top: 5px;
    float: left;
}

button.rightaligned {
    margin-top: 10px;
    float: right;
}

div.centerleft {
    display: inline-block; 
    text-align: left;
    margin-top: calc(40px + 3%);
}

.nondisable a:not(:hover) {
        color: #EEEEEE;
}

.topgap {
    margin-top: 40px;
}

.imagelink {
    width: calc(30px + 2%);
    margin: 10px;
}

.footer-style {
    background-image: linear-gradient(to right, hsla(183,95%,63%,0.27), #222831);
    background-blend-mode: color-dodge;
    margin-bottom: -15px;
    padding-bottom: 0px;
}

.white-text {
    font-family: 'Barlow', sans-serif; 
    color: #EEEEEE;
}

.paddedcard {
    padding-top: 10px;
}

.topmargin-navbargap {
    margin-top: 50px;
}

.inline-block {
    display: inline-block;
}

.floating-background {
    position: absolute;
    top: 560px;
    width: 100%;
    z-index: -1;
}

.slight-up {
    margin-bottom: 5px;
}

.displayed {
    display: inline;
}

*{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    display: none;
}

.no-display {
    display: none;
}

@media only screen and (min-width: 1000px) {
    div.fixcollapse {
        overflow: visible;
        height: 44px;
    }
}

.dropcard {
    margin: 0 calc(50px + 4%) 0 calc(50px + 4%);
    top: 10rem;
    z-index: 2;
}

img.height-limited {
    height: calc(13rem + 10vw);
    object-fit: cover;
}

h5.fancy {
    font-family: 'Barlow', sans-serif;

}

.slide-off {
        -webkit-transform: translateY(3%);
                transform: translateY(3%);
        opacity: 0;
        Transition: all ease-in 0.5s;
}

.slide-in {
    -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important;
    opacity: 1 !important;
  }
  
.music-page {
    padding-top: 100px;
    margin-left: 5px;
}
