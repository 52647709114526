.console-bgnd {
    position: fixed; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: 100%; 
    background: black;
    z-index: 1;
    font-family: 'Press Start 2P', cursive;
    text-align: left;
    color: rgb(89, 255, 0);
}

.appearing-text {
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    font-size: calc(1vw + 1rem);
    color: red;
}

.typewriter {
    overflow: hidden;
    border-right: .15em solid rgb(89, 255, 0); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(89, 255, 0); }
  }

  .nocursor {
    /*overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid black; /* The typwriter cursor */
    animation: none;
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
  }

  #command {
    caret-color: red;
    color: red;
    font-family: 'Press Start 2P', cursive;
    text-align: left;
    background-color: black;
    border: none;
  }

  #command:focus {
    outline: none;
  }

  #cube {
    margin: 50vh auto 0;
    font-size: 2rem;
    width: 4em; 
    height: 4em;
    position: relative;
    transform-style: preserve-3d;
     animation: spin 10s linear 0.5;
     animation-fill-mode: forwards;
  }

  #cube > div {
    position: absolute;
    transform-style: preserve-3d;
    animation: metal 2s linear 1;
    animation-delay: 6s;
    animation-fill-mode: forwards;
  }
  
  @keyframes spin {
    from { transform: rotateX(-0.1turn) rotateY(0turn); }
    to { transform: rotateX(-0.1turn) rotateY(1turn); }
  }

  @keyframes metal {
    from {color: rgb(89, 255, 0);}
    to {color: red;}
  }

  .pos1 {
    transform: translate3d(2em, 0em, 3em)
  }
  .pos2 {
    transform: translate3d(0em, 0em, 3em)
  }
  .pos3 {
    transform: translate3d(2em, 0em, 1em)
  }
  .pos4 {
    transform: translate3d(0em, 0em, 1em)
  }
  .pos5 {
    transform: translate3d(2em, 0em, -1em)
  }
  .pos6 {
    transform: translate3d(0em, 0em, -1em)
  }
  .pos7 {
    transform: translate3d(2em, 0em, -3em)
  }
  .pos8 {
    transform: translate3d(0em, 0em, -3em)
  }